<template>
  <div>
    <MyTable
      :columns="columns"
      :rows="reversedRows"
      :is-loading="isLoading"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'order.reference_id'">
          <b-badge
            variant="primary"
            class="p-1 text-white font-weight-bolder"
          >
            {{ props.row.order.reference_id }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'driver.full_name'">
          {{ props.row.driver.full_name }}
        </span>

        <span v-else-if="props.column.field === 'status.description'">
          <b-badge
            :variant="props.row.status.color_name"
            class="p-1 text-white font-weight-bolder w-100"
          >
            {{ props.row.status.description }}
          </b-badge>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import axios from '@axios'

export default {
  name: 'MyTask',
  components: {
    MyTable,

  },
  data() {
    return {
      selected: null,
      columns: [
        {
          label: this.$t('order_id'),
          field: 'order.reference_id',
          type: 'number',
          tdClass: 'text-nowrap align-middle text-left pl-1',
          thClass: 'text-left',
        },
        {
          label: this.$t('company_name'),
          field: 'user.name',
          tdClass: 'text-nowrap align-middle',
          sortable: false,
        },
        {
          label: this.$t('driver_name'),
          field: 'driver.full_name',
          tdClass: 'text-nowrap align-middle',
          sortable: false,
        },
        {
          label: this.$t('senders_name'),
          field: 'order.sender',
          tdClass: 'text-nowrap align-middle',
          sortable: false,
        },
        {
          label: this.$t('recipients_name'),
          field: 'order.recipient',
          tdClass: 'text-nowrap align-middle',
          sortable: false,
        },
        {
          label: this.$t('request_date'),
          field: 'date_added',
          dateInputFormat: 'dd-MM-yyyy\'T\'HH:mm',
          tdClass: 'text-nowrap align-middle',
          sortable: false,
        },
        {
          label: this.$t('reason'),
          field: 'reason',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('status'),
          field: 'status.description',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
      ],
      rows: [],
      isLoading: false,
    }
  },
  computed: {
    reversedRows() {
      return [...this.rows].reverse()
    },
  },
  created() {
    this.getOrders()
  },
  methods: {
    async getOrders() {
      this.isLoading = true
      const query = 'delivery-cancellations'
      const response = await axios.get(query)
      this.rows = response.data
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  select.swal2-select {
    width: 100%;
  }
</style>
